html {
    // This defines what 1rem is
    font-size: 62.5%; //1rem = 10px; 10px/16px = 62.5%
    color: $primary-color;
    font-family: $primary-font-family;
    
}

h1{
    font-size: $font-size-h1-sm;
    font-weight: $weight-headings;
    @media screen and (min-width: $screen-lg) {
        font-size: $font-size-h1-lg;
    }
}

h2{
    font-size: $font-size-h2-sm;
    font-weight: $weight-headings;
    @media screen and (min-width: $screen-lg) {
        font-size: $font-size-h2-lg;
    }
}

p{
    font-size: $font-size-p-sm;
    font-weight: $weight-content;
    line-height: 1.5;
    margin: 30px 0px;
    
    @media screen and (min-width: $screen-lg) {
        font-size: $font-size-p-lg;
    }
}