@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import '../../styles/settings/variables';
@import '../../styles/utilities/utilities.scss';
@import '../../styles/utilities/mixins';

.privacy_policy{

    padding: $padding-components-sm 0px 25px; 
    
    @media screen and (min-width: $screen-md) {
      padding: $padding-components-lg 0px 25px;
    }

    &__content_container{
        max-width: $page-max;
        margin: 0 auto;
        display: block;
    }
    &__content_wrapper{
        max-width: $content-max;
        margin: 0 auto;
        padding: 0 $padding-text;
    
    }

    h3{
        font-size: $font-size-p-sm;
        font-weight: $weight-headings;
        line-height: 1.5;
        margin: 30px 0px;
        
        @media screen and (min-width: $screen-lg) {
            font-size: $font-size-p-lg;
        }
    }

    &__italic{
        em{
            font-style: italic;
        }
    }

    &__bullet_list{
        list-style-type: circle;
        list-style-position: inside;
    }

    li{
        font-size: $font-size-p-sm;
        line-height: 1.5;
        margin: 20px 0px;
        @media screen and (min-width: $screen-lg) {
            font-size: $font-size-p-lg;
        }
    }

    a{
        color: $primary-color;
        cursor: pointer;
    }

}