@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import './styles/settings/variables';
@import './styles/utilities/utilities.scss';
@import './styles/utilities/mixins';


.App{
    background-color: #f6f6f6;

    &__container{
        position: relative;
        max-width: $content-max;
        margin: 0 auto;
        padding: 50px $padding-components-sm 60px;
    
        @media screen and (min-width: $screen-md) {
          padding: 50px $padding-components-lg 60px;
        }
    }
    &__main_container{
        border: 1px solid $primary-color;
        max-width: $page-max;
        margin: auto;
        background-color: $white;
    }

    &__node_container{
        padding-top: 60px;
    }
}