@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import '../../styles/settings/variables';
@import '../../styles/utilities/utilities.scss';
@import '../../styles/utilities/mixins';

.accredited_members{
    text-align: center;
    padding: $padding-components-sm 0px;

    @media screen and (min-width: $screen-md) {
        padding: $padding-components-lg 0px;
      }

    &__content_container{
        max-width: $page-max;
        margin: 0 auto;
        display: block;
    }
    &__content_wrapper{
        max-width: $content-max;
        margin: 0 auto;
        padding: 0 $padding-text;
    }

    &__title{
        padding-bottom: $padding-components-lg;
    }

    &__logo_wrapper{
        @include flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }

    &__logo_container{
        width: 98%;
        margin: 20px auto;
        @include flex;
        justify-content: center;
        align-items: center;
        max-height: 200px;
        overflow: hidden;
        position: relative;

        @media screen and (min-width: 600px) {
            width: 48%;
            margin: 35px auto;
        }

        @media screen and (min-width: $screen-lg) {
            width: 32%;
        }

        img{
            object-fit: cover;
            max-height: 90px; 
            max-width: 75%;

            @media screen and (min-width: $screen-md) {
                max-width: 75%;

            }

            @media screen and (min-width: $screen-lg) {
                max-height: 150px;  
            }
           
        }

    }

}