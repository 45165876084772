@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import '../../styles/settings/variables';
@import '../../styles/utilities/utilities.scss';
@import '../../styles/utilities/mixins';

.contact_form{
  //background-color: red;
  padding: $padding-components-sm 0px;

  @media screen and (min-width: $screen-md) {
    padding: $padding-components-lg 0px;
  }

  &__content_container{
    max-width: $page-max;
    margin: 0 auto;
    display: block;

   
  }
  &__content_wrapper{
    max-width: $content-max;
    margin: 0 auto;
    padding: 0 $padding-text;
  }
  &__title, &__info{
    text-align: center;
  }

  &__info{
    span{
      font-weight: $weight-headings;
    }
  }

  &__form_container{
    border: 1px solid $primary-color;
    padding: 70px 10%;
    margin-bottom: $after-components-sm;

    @media screen and (min-width: $screen-lg) {
      margin-bottom: $after-components-lg;
      padding: 110px 10%;
    }
  } 

  &__form{

    &__input_item{
      width: 100%;
      margin: 0px 0px 35px;
      @media screen and (min-width: $screen-lg) {
        margin: 0px 0px 45px;
      }
    }

    &__input, &__input::placeholder, &__submit{
      font-size: $font-size-form-sm;
      font-weight: $weight-content;

      @media screen and (min-width: $screen-lg) {
        font-size: $font-size-form-lg;
      }
    }

    &__input{
      width:99%;    
      border: none;
      border-bottom: 2px solid $primary-color;
      
    }

    &__input::placeholder{
      opacity: 0.5;
      
    }

    &__input:focus{
      outline: none;
    }

    &__submit_container{
      text-align: center;

      @media screen and (min-width: $screen-lg) {
        text-align: left;
      }
    }

    &__submit{
      display: inline-block;
      border: 1px solid $black;
      padding: 15px;
      margin: 20px 0px 0px;
      text-transform: uppercase;
      cursor: pointer;
      background-color: $black;
      color: $secondary-color;
      text-align: center;

      @media screen and (min-width: $screen-lg) {
        padding: 25px;
      }
      
    }
  }

  &__form_sending, &__form_sent, &__sent_status{
    display: none;
    text-align: center;
  }

  &__loading_animation {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  &__loading_animation div{
    position: absolute;
    width: 6px;
    height: 6px;
    background: #1E222C;
    border-radius: 50%;
    animation: loading-animation 1.2s linear infinite;
  }

  &__loading_animation div:nth-child(1) {
    animation-delay: 0s;
    top: 38px;
    left: 66px;
  }

  &__loading_animation div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }

  &__loading_animation div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }

  &__loading_animation div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }

  &__loading_animation div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }

  &__loading_animation div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }

  &__loading_animation div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }

  &__loading_animation div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }

  &__loading_animation div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }

  &__loading_animation div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }

  &__loading_animation div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  &__loading_animation div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }

  @keyframes loading-animation {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
}

.contact_form::after{
    
  content: "";
  background: $primary-color;
  width: 25%;
  height: 1px;
  display: block;
  margin: $after-components-sm auto 0px;

  @media screen and (min-width: $screen-lg) {
    margin: $after-components-lg auto 0px;
  }
}