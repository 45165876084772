@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import '../../styles/settings/variables';
@import '../../styles/utilities/utilities.scss';
@import '../../styles/utilities/mixins';

.Background_video{
    position: fixed;
    width: 100vw;
    height: 100vh;

    &__container{
        width: 100%;
        height: 100%;
        position: relative;

        video{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }

   
    

}