@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import '../../styles/settings/variables';
@import '../../styles/utilities/utilities.scss';
@import '../../styles/utilities/mixins';

.introduction{
    text-align: center;
    padding: $padding-components-sm 0px;
    @media screen and (min-width: $screen-md) {
        padding: $padding-components-lg 0px;
    }

    &__content_container{
        max-width: $page-max;
        margin: 0 auto;
        display: block;
    }
    &__content_wrapper{
        max-width: $content-max;
        margin: 0 auto;
        padding: 0 $padding-text;

        a{
            color: #7b4df5;
            cursor: pointer;
        }
    }
}

.introduction::after{
    
    content: "";
    background: $primary-color;
    width: 25%;
    height: 1px;
    display: block;
    margin: $after-components-sm auto 0px;

    @media screen and (min-width: $screen-lg) {
        margin: $after-components-lg auto 0px;
    }
}