// ORDER
//-------
// Normalize
// Variables
// Typography
// Mixins
// Reset
//-------


@import './settings/fonts';
@import './settings/variables';

:global {
	@import './tools/tools.scss';
	@import './utilities/utilities.scss';
	@import './base/base.scss';
}

@import './settings/typography';