@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import '../../styles/settings/variables';
@import '../../styles/utilities/utilities.scss';
@import '../../styles/utilities/mixins';

.node{
    text-align: center;
    padding: 20px 0px;
    
    @media screen and (min-width: $screen-md) {
        padding: $padding-components-lg 0px;
    }

    &__content_container{
        max-width: $page-max;
        margin: 0 auto;
        display: block;
    }
    &__content_wrapper{
        max-width: $content-max;
        margin: 0 auto;
        padding: 0 $padding-text;
    }

    &__logo_container{
        display: inline-block;
        a{
            @include flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            color: $primary-color;
        }
    }

    &__supported{
        font-size: 0.9rem;
        font-family: $font-effra;
        font-weight: $weight-headings;
        text-transform: uppercase;
        font-style: normal;
        margin: 7px 10px 0px 0px;
        color: $white;

        @media screen and (min-width: $screen-md) {
            font-size: 1.2rem;
        }
    }

    &__logo{
        width: 50px;
        height: auto;
    }


}