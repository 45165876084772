@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import '../../styles/settings/variables';
@import '../../styles/utilities/utilities.scss';
@import '../../styles/utilities/mixins';

.footer{

    padding: $padding-components-sm 0px 25px; 
    
    @media screen and (min-width: $screen-md) {
      padding: $padding-components-lg 0px 25px;
    }

    &__content_container{
        max-width: $page-max;
        margin: 0 auto;
        display: block;
    }
    &__content_wrapper{
        max-width: $content-max;
        margin: 0 auto;
        padding: 0 $padding-text;
        @include flex;
        justify-content: space-between;
    
    }

    &__copy_right, &__privacy{
        font-size: 1rem;
        text-align: center;
        @media screen and (min-width: $screen-lg) {
            font-size: 1.6rem;
        }
    }

    &__privacy{
        a{
            text-decoration: none;
            color: $primary-color;
            cursor: pointer;
        }
    }

    &__copy_right{
        opacity: 0.5;
    }
}